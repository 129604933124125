export function isAbsoluteHref(href: string) {
   return /^(?:[a-z]+:)?\/\//i.test(href);
}

export function isRelativeHref(href: string) {
   return !isAbsoluteHref(href);
}

export function isIFixitUSUrl(url: URL) {
   return isIfixitUrl(url) || isCominorUrl(url);
}

export function isIfixitUrl(url: URL) {
   return /^(?:www\.)?ifixit\.com$/.test(url.hostname);
}

export function isCominorUrl(url: URL) {
   return /^(?:[^.]+\.)?cominor\.com$/.test(url.hostname);
}

export function isNextJsPath(path: string) {
   return (
      /^\/cart\/?$/.test(path) ||
      /^\/Store\/?$/.test(path) ||
      /^\/Parts(?:\/.*)?$/.test(path) ||
      /^\/Tools(?:\/.*)?$/.test(path) ||
      /^\/Shop\/[^/]+\/?$/.test(path) ||
      /^\/products\/[^/]+\/?$/.test(path) ||
      /^\/Troubleshooting\/[^/]+\/?$/.test(path) ||
      /^\/Troubleshooting(?:\/[^/]+){3}\/?$/.test(path)
   );
}
