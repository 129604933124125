import * as Sentry from '@sentry/browser';
import { sentryErrorIntegration, ignoreUserAgentIntegration } from '@ifixit/sentry';
import { StorefrontApiValidationError } from '@ifixit/shopify-cart-sdk';

const ERROR_SAMPLE_RATE_PRODUCTION = 0.05;
const ERROR_SAMPLE_RATE_DEV = 0;

const TRACE_SAMPLE_RATE = App.isProduction ? 0.005 : 0;

const errorSampleRate = App.isProduction ? ERROR_SAMPLE_RATE_PRODUCTION : ERROR_SAMPLE_RATE_DEV;

let errorsLeftToSend = 1;
const forceSentry = App.canIForceSentryReporting && /(forceSentry)/.test(window.location.search);
const performanceOptions = App.sentryPerformance
   ? {
        integrations: [
           Sentry.browserTracingIntegration(),
           sentryErrorIntegration(),
           ignoreUserAgentIntegration(),
           Sentry.thirdPartyErrorFilterIntegration({
              filterKeys: ['ifixitFirstParty'],
              behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
           }),
        ],
        tracesSampleRate: TRACE_SAMPLE_RATE,
     }
   : {
        integrations: [
           sentryErrorIntegration(),
           ignoreUserAgentIntegration(),
           Sentry.thirdPartyErrorFilterIntegration({
              filterKeys: ['ifixitFirstParty'],
              behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
           }),
        ],
     };

let sampleRate = forceSentry ? 1 : errorSampleRate;
let shouldSendToSentry = Math.random() < sampleRate;

Sentry.init({
   dsn: App.SENTRY_DSN_JS,
   environment: App.isProduction ? 'production' : 'dev',
   release: App.sentryRelease,
   ignoreErrors: [
      'TypeError: Network request failed',
      // Storefront api validations are our own limits we put on users
      // ie not buying more than X amount of batteries
      // Since they are getting caught and handled, lets not report them
      StorefrontApiValidationError.ErrorMsg,
   ],
   ...performanceOptions,

   // We effect our sampling in beforeSend, so we tell sentry to sample all
   // errors
   sampleRate: 1,
   beforeSend: function (event) {
      if (!shouldSendToSentry) {
         return null;
      }

      if (errorsLeftToSend <= 0) {
         return null;
      }
      errorsLeftToSend--;
      return event;
   },
   normalizeDepth: 10,
});

Sentry.setTags(App.sentryTags);
Sentry.setUser({ ip_address: '{{auto}}' });
