import { SentryError } from '@ifixit/sentry';
import { log } from './logger';

export function assertNever(_x: never): never {
   throw new SentryError('Unexpected object: ' + _x);
}

export function isRecord<T>(val: unknown): val is Record<string, T> {
   return val != null && typeof val === 'object';
}

const isProduction = process.env.NODE_ENV === 'production';
const enableLogging = Boolean(process.env.NEXT_PUBLIC_LOGGING);
const prefix = 'invariant failed';

export function invariant(condition: unknown, message: string | (() => string)): asserts condition {
   if (condition) {
      return;
   }
   const sentryMsg = `${prefix}: ${typeof message === 'function' ? message() : message}`;
   throw new SentryError(sentryMsg);
}

export function isError(_x: unknown): _x is Error {
   return _x instanceof Error;
}

/**
 * Calls the async function and logs the time it takes for the returned promise
 * to resolve.
 */
export function timeAsync<T>(name: string, asyncFunction: () => Promise<T>): Promise<T> {
   const done = time(name);
   return asyncFunction().finally(done);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncFn<P extends any[], R> = (...params: P) => Promise<R>;

/**
 * Wraps an async function so that it logs the time it takes for the
 * returned promise to resolve.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withAsyncTiming<P extends any[], R>(
   name: string,
   asyncFunction: AsyncFn<P, R>
): (...args: P) => Promise<R | void> {
   return (...params: P): Promise<R> => {
      const done = time(name);
      return asyncFunction(...params).finally(done);
   };
}

export function timeSync<T>(name: string, syncFunction: () => T): T {
   const done = time(name);
   const response = syncFunction();
   done();
   return response;
}

export function withTiming<ARGS extends unknown[], RETURN>(
   name: string,
   promiseFunction: (...args: ARGS) => Promise<RETURN>
) {
   return (...args: ARGS) => {
      const done = time(name);
      return promiseFunction(...args).finally(done);
   };
}

export function withSyncTiming<ARGS extends unknown[], RETURN>(
   name: string,
   syncFunction: (...args: ARGS) => RETURN
) {
   return (...args: ARGS) => {
      const done = time(name);
      const ret = syncFunction(...args);
      done();
      return ret;
   };
}

function noOp() {}
const silentTimer = function (_timerName: string) {
   return noOp;
};

const loggingTimer = (_timerName: string) => {
   const t = performance.now();
   return () => {
      const taken = performance.now() - t;
      log.info.timing(_timerName, taken);
   };
};

type Timer = (name: string) => () => void;
const time: Timer = !isProduction || enableLogging ? loggingTimer : silentTimer;

export function isBlank(value: unknown): boolean {
   return value == null || isBlankString(value) || isBlankArray(value) || isBlankObject(value);
}

function isBlankString(value: unknown): boolean {
   return typeof value === 'string' && value.trim() === '';
}

function isBlankArray(value: unknown): boolean {
   return Array.isArray(value) && value.length === 0;
}

function isBlankObject(value: unknown): boolean {
   return typeof value === 'object' && value != null && Object.keys(value).length === 0;
}

export function isPresent(text: unknown): text is string {
   return typeof text === 'string' && text.length > 0;
}

export function presence(text: string | null | undefined): string | null {
   return isPresent(text) ? text : null;
}

export function filterNullableItems<I>(items?: (I | null | undefined)[] | null): NonNullable<I>[] {
   return (items?.filter(item => item != null) as NonNullable<I>[]) || [];
}

export function isKeyOf<T extends object>(key: PropertyKey, obj: T): key is keyof T {
   return key in obj;
}

/**
 * Executes a callback function when the browser is idle, with a fallback to setTimeout
 * for browsers that don't support requestIdleCallback.
 *
 * @param callback - Function to execute when browser is idle
 * @param options - Optional configuration object
 * @param options.timeout - Maximum time (in ms) to wait before the callback is invoked (default: 5000)
 */
export const executeWhenIdle = (callback: () => void, options: { timeout?: number } = {}): void => {
   const timeout = options.timeout ?? 5000;

   if ('requestIdleCallback' in window) {
      window.requestIdleCallback(callback, { timeout });
   } else {
      // If requestIdleCallback is not available postpones execution to the next event loop cycle
      setTimeout(callback, 0);
   }
};

/**
 * Executes a callback function in the next event loop cycle, allowing the browser
 * to process any pending renders or updates before executing the callback.
 *
 * @param callback - Function to execute in the next event loop cycle
 */
export const executeNextLoop = (callback: () => void): void => {
   setTimeout(callback, 0);
};
